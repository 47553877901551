.renew-button {
    padding: 5px 10px;
    border-radius: 4px;
    margin: 20px;
    cursor: pointer;
    user-select: none;
    background-color: #EF8200;
    color: white;
    font-weight: bold;
}

.renew-button:hover {
    background-color: #cc7000;
    color: white;
    font-weight: bold;
}

body {
    background: #fffaef;
}